import { FunctionComponent, useEffect, useState } from "react";
interface Props {
  data: any;
}

export const About: FunctionComponent<Props> = (props) => {
  const [windowSize, setWindowSize] = useState<any>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="about-text">
              <h2>¿Por qué Pretal?</h2>
              <p style={{ fontSize: "22px" }}>
                {props.data ? props.data.paragraph : "loading..."}
              </p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 20,
                  justifyContent: "center",
                }}
              >
                {props.data
                  ? props.data.Why.map((d: any, i: any) => (
                      <div
                        key={i}
                        style={{
                          backgroundColor: "#ffffff",
                          border: "none",
                          width: "350px",
                          borderRadius: "24px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          padding: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <i
                            className={d.icon}
                            style={{ fontSize: "24px", color: "#9fe66d" }}
                          />
                          <div
                            style={{
                              color: "#190d30",
                              fontSize: "3rem",
                              fontWeight: 600,
                            }}
                          >
                            {d.title}
                          </div>
                        </div>
                        <div style={{ fontSize: "2rem" }}>{d.text}</div>
                      </div>
                    ))
                  : "loading"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
