import { useState } from "react";

export const Navigation = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed((prev) => !prev);
  };

  const closeNavbar = () => {
    setCollapsed(true);
  };

  const handleLinkClick = (event: any) => {
    event.preventDefault();
    closeNavbar();
    const targetId = event.target.getAttribute("href");
    document.querySelector(targetId)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className={`navbar-toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleNavbar}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div className="navbar-brand" style={{ marginTop: "-30px" }}>
            <img
              src={
                "https://pretaldev-storage-65bc4cdb185512-dev.s3.us-east-2.amazonaws.com/public/pretal-logo.jpg"
              }
              style={{ width: 175, height: 71 }}
              alt="Logo"
            />
          </div>
        </div>

        <div
          className={`collapse navbar-collapse ${collapsed ? "" : "in"}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="#features"
                style={{ textTransform: "capitalize" }}
                className="page-scroll"
                onClick={handleLinkClick}
              >
                Cómo funciona
              </a>
            </li>
            <li>
              <a
                style={{ textTransform: "none" }}
                href="#about"
                className="page-scroll"
                onClick={handleLinkClick}
              >
                Por qué elegirnos
              </a>
            </li>
            <li>
              <a
                style={{ textTransform: "capitalize" }}
                href="#faq"
                className="page-scroll"
                onClick={handleLinkClick}
              >
                FAQ
              </a>
            </li>
            <li>
              <a
                style={{
                  textTransform: "capitalize",
                  border: `2px solid rgb(92 122 195)`,
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "8px",
                  textDecoration: "none",
                  width: "150px",
                  textAlign: "center",
                }}
                href="https://play.google.com/store/apps/details?id=com.dbusc0793.petralfrontapp"
                className="page-scroll"
              >
                APP ANDROID
              </a>
            </li>
            <li>
              <a
                style={{
                  textTransform: "capitalize",
                  border: `2px solid rgb(92 122 195)`,
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "8px",
                  textDecoration: "none",
                  width: "150px",
                  textAlign: "center",
                }}
                href="https://apps.apple.com/ar/app/pretal/id6584529938"
                className="page-scroll"
              >
                APP IOS
              </a>
            </li>
            <li>
              <a
                style={{ textTransform: "capitalize" }}
                href="https://www.instagram.com/pretal.ar/"
                className="page-scroll"
              >
                <span style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <div style={{ width: "24px", height: "24px" }}>
                    <img
                      style={{ height: "24px", width: "24px" }}
                      src="https://pretaldev-storage-65bc4cdb185512-dev.s3.us-east-2.amazonaws.com/public/instagram.png"
                      alt="ig"
                    ></img>
                  </div>
                  Instagram
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
