import { useState } from "react";
import { About } from ".././components/about";
import { Contact } from ".././components/contact";
import { Features } from ".././components/features";
import { Header } from ".././components/header";
import { Navigation } from ".././components/navigation";
import JsonData from ".././data/data.json";
import "../App.css";
import FAQ from "../components/faq";

const LandingPage = () => {
  const [landingPageData] = useState<any>(JsonData);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <FAQ />
      <Contact data={landingPageData.Contact} />
      <a className="whatsapp_float" href="https://wa.me/+5491135216646">
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
};

export default LandingPage;
