import { FunctionComponent } from "react";
import DogCatFoto from "../common/img/dogcat.jpg";

interface Props {
  data: any;
}

export const Contact: FunctionComponent<Props> = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-6">
            <div className="row">
              <div className="section-title">
                <h2>Estamos para vos</h2>
                <p style={{ fontSize: "22px" }}>
                  Envianos mensaje cuando quieras. Nuestro equipo esta listo
                  para acompañarte.
                </p>
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: 30 }}>
              <div
                className="contact-item"
                style={{ display: "flex", gap: 20 }}
              >
                <a
                  href="https://www.instagram.com/pretal.ar/"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 10,
                      gap: 10,
                      backgroundColor: "#ffffff",
                      borderRadius: 30,
                      color: "#000000",
                      fontWeight: 600,
                    }}
                  >
                    <div style={{ width: "24px", height: "24px" }}>
                      <img
                        style={{ height: "24px", width: "24px" }}
                        src="https://pretaldev-storage-65bc4cdb185512-dev.s3.us-east-2.amazonaws.com/public/instagram.png"
                        alt="ig"
                      ></img>
                    </div>
                    INSTAGRAM
                  </div>
                </a>
                <a
                  href="https://wa.me/+5491135216646"
                  style={{ width: "150px" }}
                >
                  <div
                    style={{
                      padding: 10,
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      backgroundColor: "#ffffff",
                      borderRadius: 30,
                      color: "#000000",
                      fontWeight: 600,
                    }}
                  >
                    <a
                      className="whatsapp_no_float"
                      href="https://wa.me/+5491135216646"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="fa fa-whatsapp"></div>
                    </a>
                    WHATSAPP
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <img
              src={DogCatFoto}
              className="img-responsive"
              style={{ borderRadius: "24px" }}
              alt=""
            />
          </div>
        </div>
      </div>
      <div id="footer">
        <div>&copy; 2024 Pretal. Todos los derechos reservados.</div>
        <a href="https://www.pretal.com.ar/terms">Términos y condiciones</a>
      </div>
    </div>
  );
};
