export const Terms = () => {
  return (
    <div>
      <h1
        style={{
          backgroundColor: "lightblue",
          padding: 20,
          margin: 0,
          color: "#ffffff",
        }}
      >
        PRETAL - Términos y condiciones
      </h1>
      <div style={{ padding: '5px' }}>
        1.Alcance del vínculo con el usuario Las presentes Condiciones de uso
        (“Condiciones”) regulan el uso que usted (“Usuario”)_hará exclusivamente
        desde la República Argentina del contenido de esta aplicación
        (“Aplicación”) y/o de los que pudieren existir en la página web
        www.pretal.com.ar, (el/los “Contenido”/”Contenidos”), loos cuales son
        puestos a disposición sin costo para el Usuario, y sujetos a los
        siguientes términos y condiciones, por PRETAL en la República Argentina (“Pretal”). POR FAVOR LEER
        DETENIDAMENTE ESTAS CONDICIONES ANTES DE ACCEDER O USAR LOS SERVICIOS.
        SE CONSIDERARÁ QUE USTED HA ACEPTADO LOS MISMOS POR EL USO DE ESTA
        APLICACIÓN Y/O EL DE LA PÁGINA WEB WWW.PRETAL.COM.AR Mediante el acceso
        que usted realiza a los Contenidos y/o al uso de los mismos, usted
        acuerda vincularse jurídicamente con PRETAL exclusivamente por estas
        Condiciones, las cuales no establecen ninguna relación contractual ni
        extracontractual entre usted y Pretal, quien actúa en relación a todos
        los Contenidos simplemente como una plataforma de intermediación para
        que usted y los cuidadores de mascotas (“Prestadores”) que se registren
        en el sitio www.pretal.com.ar (“Sitio”) o esta Aplicación, accedan a los
        servicios de cuidado/hospedaje de mascotas que se publicitan por los
        Prestadores en el Sitio o esta Aplicación (“Servicios”), los cuales son
        ofertados por dichos Prestadores, y contratados exclusivamente entre
        usted y ellos luego de haber evaluado ambos la conveniencia de concertar
        tal locación de Servicios. De manera que una vez que usted y los
        Prestadores accedan a la información que ambos han provisto en el Sitio,
        y decidan eventualmente contratar entre ustedes y requerir los Servicios
        que los Prestadores ofrecen, se generará una relación contractual
        exclusivamente entre usted y el Prestador que ha elegido, sin que Pretal
        sea parte en tal relación, ni participe en modo alguno en el proceso de
        evaluación y eventual concertación de dicho contrato que hagan usted y
        el Prestador, luego de haber evaluado todo cuanto consideren relevante
        para tomar dicha decisión. Pretal no percibirá suma alguna de vuestra
        parte, con relación a los Contenidos y/o a los Servicios. Estas
        Condiciones sustituyen expresamente cualquier acuerdo previo o
        manifestación que usted y/o Pretal pudiesen haberse realizado de
        cualquier modo relacionado al Sitio y/o a los Contenidos y/o a los
        Servicios, o por haber accedido a nuestra Plataforma. La Aplicación también
        facilitará el Servicio de pago y contratación por cuenta y/o orden de
        los Prestadores, por lo que el uso de los aplicativos referidos por
        parte de los usuarios, tampoco implicará la generación de una relación
        contractual con la Aplicación, que no percibiera monto alguno por este uso
        ni por la prestación de este servicio a los usuarios, por lo que ello no
        generará ninguna obligación ni compromiso, ni generará ninguna garantía
        explícita ni implícita de la Aplicación en favor de los usuarios. Pretal
        podrá modificar sin previo aviso estas Condiciones o en general dejar de
        publicar los Contenidos en el Sitio o esta Aplicación, en cualquier
        momento y por cualquier motivo, sin que esto genere daño alguno a los
        Usuarios. Las modificaciones serán efectivas después de la publicación
        por parte de Pretal de dichas Condiciones actualizadas en esta
        ubicación. Su acceso al Sitio y/o el uso de los Servicios después de
        dicha publicación, constituye su consentimiento a vincularse por las
        Condiciones y sus modificaciones. La política de Pretal frente a los
        datos que tanto los Usuarios como los Prestadores provean al Sitio, será
        la de no compartir ninguno de tales datos con ningún tercero, salvo los
        que resulten estrictamente necesarios al Usuario o al Prestador para
        prestar el servicio promovido. Los Usuarios aceptan al utilizar los
        Contenidos y el Servicio y autorizan expresamente a Pretal, a proveer al
        Prestador con quien el Usuario haya contratado, la información necesaria
        para la prestación del Servicio. Pretal cumple con toda normativa
        vigente en cuanto a seguridad de datos de tarjetas de crédito,
        domicilio, documentación y/o demás información que tanto los Usuarios
        como los Prestadores, provean a Pretal, exclusivamente para que sean
        utilizados en la prestación de los Servicios. Con respecto a la
        ubicación de los Usuarios, Pretal sólo compartirá su geolocalización sin
        detalles, simplemente para establecer un radio y un índice de cercanía
        con respecto al Servicio. Pretal declara expresamente y el Usuario así
        lo acepta, que previo a que el Prestador decida si acepta o no la
        propuesta de Servicios que le realiza el Usuario en cada caso concreto,
        Pretal le informará al Prestador los datos requeridos para la prestación
        del Servicio. Será, en consecuencia, de exclusiva responsabilidad y
        decisión del Prestador si acepta o no prestar el Servicio en dicho
        domicilio, y en caso que no lo haga, el Usuario declara que nada tendrá
        que reclamar al Prestador ni a Pretal por dicha negativa. 2. Los
        Servicios Los Servicios a los que se hace referencia en estas
        Condiciones, lo constituyen los servicios de cuidado y/o hospedaje de
        mascotas que realizan los Prestadores, a pedido y a favor de los
        Usuarios, que ambos (Prestadores y Usuarios), concertan libre y
        voluntariamente sin la intervención de Pretal a través de la información
        que ambos han provisto en el Sitio y/o la Aplicación. Pretal no ha
        verificado la fidelidad y/o corrección y exactitud de ninguna
        información provista en el Sitio y/o la Aplicación por los Usuarios y/o
        los Prestadores, y desde ya no se responsabiliza por ello ni por ningún
        evento que ocurra entre los Usuarios y los Prestadores, previo a la
        concertación del vínculo contractual entre ellos, y/o durante la
        ejecución del mismo, y/o como consecuencia de éste. La Aplicacion no
        proporciona servicios de cuidado de mascotas. La Aplicacion es un lugar
        neutral para Prestadores de servicios y dueños de mascotas. La Aplicacion
        no es un proveedor de servicios y, a excepción del soporte telefónico de
        emergencia y otros recursos y soporte descritos específicamente en el
        Servicio La Aplicacion, no brinda servicios de cuidado de mascotas. No
        hacemos representaciones ni garantías sobre la calidad del alojamiento,
        el cuidado de mascotas, el paseo de perros, el cuidado de la casa u
        otros servicios proporcionados por los Prestadores de servicios ("
        Servicios de cuidado de mascotas”), o sobre sus interacciones y tratos
        con los usuarios. Los Prestadores de servicios que figuran en La Aplicacion
        no están bajo la dirección o el control de La Aplicacion, y los Prestadores
        de servicios determinan a su propia discreción cómo brindar servicios de
        cuidado de mascotas. Si bien brindamos orientación general en nuestro
        Sitio a los Prestadores de servicios sobre la seguridad y el cuidado de
        las mascotas y a los Usuarios sobre la selección y contratación de
        Prestadores de servicios, La Aplicacion no emplea, recomienda ni respalda a
        los Prestadores de servicios ni a los Usuarios y, en la medida máxima
        permitida por las leyes aplicables. la ley, no seremos responsables por
        el desempeño o la conducta de los Prestadores de servicios o dueños de
        mascotas, ya sea en línea o fuera de línea. Realizamos una revisión
        inicial de los perfiles de los Prestadores de servicios y facilitamos
        verificaciones de antecedentes o verificaciones de identificación (cada
        una como se describe en la Sección 10, a continuación) sobre los
        Prestadores de servicios realizados por un tercero, pero, salvo que se
        especifique explícitamente en el Servicio La Aplicacion (y solo en la
        medida en que se especifique), no evalúe a los Prestadores de servicios
        ni a los Usuarios. Debe tener cuidado y utilizar su criterio
        independiente antes de contratar a un Proveedor de servicios,
        proporcionar Servicios de cuidado de mascotas o interactuar con los
        usuarios a través del Servicio La Aplicacion. Los Usuarios y los
        Prestadores de servicios son los únicos responsables de tomar las
        decisiones que sean mejores para ellos y sus mascotas. Por ejemplo, cada
        usuario del Servicio La Aplicacion es responsable de mantener al día las
        vacunas de su propia mascota, y no seremos responsables por el hecho de
        que alguien no vacune a su mascota. de lo contrario, no evalúe a los
        Prestadores de servicios ni a los dueños de mascotas. Debe tener cuidado
        y utilizar su criterio independiente antes de contratar a un Proveedor
        de servicios, proporcionar Servicios de cuidado de mascotas o
        interactuar con los usuarios a través del Servicio La Aplicacion. Los
        Usuarios y los Prestadores de servicios son los únicos responsables de
        tomar las decisiones que sean mejores para ellos y sus mascotas. Por
        ejemplo, cada usuario del Servicio La Aplicacion es responsable de mantener
        al día las vacunas de su propia mascota, y no seremos responsables por
        el hecho de que alguien no vacune a su mascota. de lo contrario, no
        evalúe a los Prestadores de servicios ni a los dueños de mascotas. Debe
        tener cuidado y utilizar su criterio independiente antes de contratar a
        un Proveedor de servicios, proporcionar Servicios de cuidado de mascotas
        o interactuar con los usuarios a través del Servicio La Aplicacion. Los
        Usuarios y los Prestadores de servicios son los únicos responsables de
        tomar las decisiones que sean mejores para ellos y sus mascotas. Por
        ejemplo, cada usuario del Servicio La Aplicacion es responsable de mantener
        al día las vacunas de su propia mascota, y no seremos responsables por
        el hecho de que alguien no vacune a su mascota. Los Usuarios y los
        Prestadores de servicios son los únicos responsables de tomar las
        decisiones que sean mejores para ellos y sus mascotas. Por ejemplo, cada
        usuario del Servicio La Aplicacion es responsable de mantener al día las
        vacunas de su propia mascota, y no seremos responsables por el hecho de
        que alguien no vacune a su mascota. Los Usuarios y los Prestadores de
        servicios son los únicos responsables de tomar las decisiones que sean
        mejores para ellos y sus mascotas. Por ejemplo, cada usuario del
        Servicio La Aplicacion es responsable de mantener al día las vacunas de su
        propia mascota, y no seremos responsables por el hecho de que alguien no
        vacune a su mascota. A los fines de ofrecer los Servicios, tanto los
        Prestadores como los Usuarios crearán sendos perfiles en el Sitio /o la
        Aplicación, conforme especificaciones técnicas que Pretal determine al
        presente y/o pueda determinar en el futuro. A su vez, los Usuarios deben
        tener una foto de perfil que cumpla los estándares que solicitará
        Pretal. Reservas. Los Usuarios y los Prestadores de servicios
        realizan transacciones entre ellos en el Servicio La Aplicacion cuando
        ambos acuerdan una "reserva" que especifica las tarifas, el período de
        tiempo, la política de cancelación y otros términos para la prestación
        de Servicios de cuidado de mascotas a través del mecanismo de reserva
        proporcionado en el La Aplicacion. Servicio (una “Reserva”). Una Reserva
        puede ser iniciada por un Proveedor de servicios o un Propietario de
        mascotas seleccionando los tipos de Servicios de cuidado de mascotas que
        se brindarán y luego siguiendo las indicaciones que aparecen en la
        pantalla. Si es propietario de una mascota e inicia una Reserva, acepta
        pagar los Servicios de cuidado de mascotas descritos en la Reserva al
        hacer clic en "Solicitar y pagar". Si es propietario de una mascota y un
        proveedor de servicios inicia una reserva, acepta pagar los servicios de
        cuidado de mascotas descritos en la reserva al hacer clic en "Pagar
        ahora". Todas las solicitudes están sujetas a la aceptación por parte de
        la parte receptora. La parte receptora no está obligada a aceptar su (o
        cualquier) solicitud y puede, a su discreción, rechazarla por cualquier
        motivo. Usted reconoce que, una vez que completa una Reserva, acepta
        cumplir con el precio y otros términos de esa Reserva, como se reconoce
        en la confirmación de la Reserva. 3. Restricciones Usted, como Usuario,
        no podrá: (i) retirar y/o reclamar cualquier derecho de autor, marca
        registrada u otra nota de propiedad de cualquier parte de los Servicios;
        (ii) reproducir, modificar, preparar obras derivadas sobre los
        Servicios, distribuir, licenciar, arrendar, revender, transferir,
        exhibir públicamente, presentar públicamente, transmitir, retransmitir o
        explotar de otra forma los Servicios, excepto como se permita
        expresamente por Pretal; (iii) causar o lanzar cualquier programa o
        script con el objeto de extraer, indexar, analizar o de otro modo
        realizar prospección de datos de cualquier parte de los Servicios o
        sobrecargar o bloquear indebidamente la operación y/o funcionalidad de
        cualquier aspecto de los Servicios; o (iv) intentar obtener un acceso no
        autorizado o dañar cualquier aspecto de los Servicios, o el Contenido
        del Sitio y/o la Aplicación. 4. Titularidad Los Servicios y todos los
        derechos relativos a éstos, son y permanecerán de la propiedad de
        Pretal, o de quien éste indique. Ninguna de estas Condiciones ni su uso
        de los Servicios le transfieren a usted como Usuario, ni a los
        Prestadores por ningún concepto, ningún derecho. 5. Uso de los Servicios
        a.- Cuentas de usuario. Con el fin de usar la mayor parte de los
        aspectos de los Servicios, usted debe registrarse y mantener activa una
        cuenta personal de usuario de los Servicios (“Cuenta”). Para obtener una
        Cuenta debe tener como mínimo 18 años. El registro de la cuenta le
        requiere que comunique a Pretal determinada información personal, como
        su nombre, dirección, número de teléfono móvil, así como por lo menos un
        método de pago válido (bien una tarjeta de crédito o bien un socio de
        pago aceptado). También se requerirá la siguiente información respecto
        de las mascotas que serán cuidadas y/u hospedadas conforme los servicios
        promocionados. Usted se compromete a mantener la información en su
        Cuenta de forma exacta, completa y actualizada. Si no mantiene la
        información de Cuenta de forma exacta, completa y actualizada, incluso
        el tener un método de pago inválido o que haya vencido, podrá resultar
        en su imposibilidad para acceder y utilizar los Servicios. Usted es
        responsable de toda la actividad que ocurre en su Cuenta y se compromete
        a mantener en todo momento de forma segura y secreta el nombre de
        usuario y la contraseña de su Cuenta. Pretal declara bajo juramento que
        no aplicará ningún cargo a los Usuarios, ni percibirá ningún honorario
        de éstos con relación a los Servicios, limitándose a percibir una
        comisión pagadera exclusivamente por el Prestador, quien lo autorizará a
        practicar la retención correspondiente del honorario que ésta haya
        acordado con el Usuario. b.- Requisitos y conducta del usuario.
        Usted no podrá autorizar a terceros a utilizar su Cuenta. No podrá ceder
        o transferir de otro modo su Cuenta a cualquier otra persona o entidad.
        Usted acuerda cumplir con todas las leyes aplicables al utilizar los
        Servicios y solo podrá utilizar los Servicios con fines legítimos. En el
        uso de los Servicios, no causará estorbos, molestias, incomodidades o
        daños a la propiedad. En algunos casos, se le podrá requerir que
        facilite un documento de identidad u otro elemento de verificación de
        identidad para el acceso o uso de los Servicios, y usted acepta que se
        le podrá denegar el acceso o uso de los Servicios si se niega a
        facilitar el documento de identidad o el elemento de verificación de
        identidad. c.- Mensajes de texto. Al crear una Cuenta, usted acepta que
        Pretal pueda enviarle mensajes de texto informativos (SMS) o en
        cualquier otro formato electrónico, relacionados a la prestación de los
        Servicios. Usted podrá solicitar en cualquier momento no continuar
        recibiendo en el futuro mensajes de texto informativos (SMS) o whatsapp
        o mensajes de Pretal en cualquier otro formato electrónico, enviando un
        correo electrónico a info@pretal.com.ar, indicando que no desea recibir
        más dichos mensajes, junto con el número de teléfono del dispositivo
        móvil que recibe los mensajes. Usted reconoce que solicitar la no
        recepción de mensajes de texto informativos (SMS) o en cualquier otro
        formato electrónico, podrá afectar al uso que usted haga de los
        Servicios. d.- Contenido proporcionado por el Usuario. Pretal podrá, a
        su sola discreción, permitirle cuando considere oportuno, que envíe,
        cargue, publique o de otro modo ponga a disposición de Pretal en el
        Sitio y/o la Aplicación, contenido e información de texto, audio y/o
        visual, incluidos comentarios y opiniones relativos a los Servicios
        (“Contenido de Usuario”). Todo Contenido de Usuario facilitado por usted
        seguirá siendo de su propiedad. No obstante, al proporcionar Contenido
        de Usuario a Pretal, usted otorga una licencia mundial, perpetua,
        irrevocable, transferible, libre de regalías, con derecho a
        sublicenciar, usar, copiar, modificar, crear obras derivadas,
        distribuir, exhibir públicamente, presentar públicamente o de otro modo
        explotar de cualquier manera dicho Contenido de Usuario en todos los
        formatos y canales de distribución, conocidos ahora o ideados en un
        futuro (incluidos en relación con los Servicios y el negocio de Pretal y
        en sitios y servicios de terceros), sin más aviso o consentimiento de
        usted y sin requerirse el pago a usted o a cualquier otra persona o
        entidad. Usted declara y garantiza que: (i) es el único y exclusivo
        propietario de todo el Contenido de Usuario o que tiene todos los
        derechos, licencias, consentimientos y permisos necesarios para otorgar
        a Pretal la licencia al Contenido de usuario como establecido
        anteriormente; y (ii) ni el Contenido de Usuario ni su presentación,
        carga, publicación o puesta a disposición de otro modo de dicho
        Contenido de Usuario, ni el uso por parte de Pretal del Contenido de
        Usuario como está aquí permitido, infringirán, malversarán o violarán la
        propiedad intelectual o los derechos de propiedad de un tercero o los
        derechos de publicidad o privacidad o resultarán en la violación de
        cualquier ley o reglamento aplicable. Usted acuerda no proporcionar
        Contenido de Usuario que sea difamatorio, calumnioso, odioso, violento,
        obsceno, pornográfico, ilícito o de otro modo ofensivo, como determine
        Pretal, a su sola discreción, tanto si dicho material pueda estar
        protegido o no por la ley. Pretal podrá, a su sola discreción y en
        cualquier momento y por cualquier motivo, sin avisarle previamente,
        revisar, controlar o eliminar Contenido de Usuario, pero sin estar
        obligada a ello. d.-Acceso a la red y dispositivos. Usted es responsable
        de obtener el acceso a la red de datos necesario para utilizar los
        Servicios. Podrán aplicarse las tarifas y tasas de datos y mensajes de
        su red móvil si usted accede o utiliza los Servicios desde un
        dispositivo inalámbrico y usted será responsable de dichas tarifas y
        tasas. Usted es responsable de adquirir y actualizar el hardware
        compatible o los dispositivos necesarios para acceder y utilizar los
        Servicios y Aplicaciones y cualquier actualización de estos. Pretal no
        garantiza que los Servicios, o cualquier parte de estos, funcionen en
        cualquier hardware o dispositivo particular. Además, los Servicios
        podrán ser objeto de disfunciones o retrasos inherentes al uso de
        Internet y de las comunicaciones electrónicas. e.-Emergencias.
        Recomendamos que los dueños de mascotas proporcionen a sus Prestadores
        de servicios información de contacto donde se les pueda localizar en
        caso de que sea necesaria la atención médica de una mascota. Los
        Prestadores de servicios acuerdan comunicarse de inmediato con los
        Usuarios en caso de que dicha atención sea necesaria o, si el
        Propietario de mascotas no está disponible, comunicarse con Pretal al
        número de teléfono o la dirección de correo electrónico correspondientes
        que figuran en la tabla al final de estos Términos. Si usted es
        propietario de una mascota, por la presente autoriza a su proveedor de
        servicios y Pretal a obtener y autorizar la prestación de atención
        veterinaria para su mascota si no se puede localizar a usted mismo para
        autorizar la atención en una situación de emergencia. En tal caso,
        también autoriza al veterinario de su mascota a divulgar los registros
        veterinarios de su mascota a Pretal. Si su proveedor de servicios se
        comunica con usted con una solicitud para autorizar la atención médica
        de su mascota y usted se niega, usted libera al Proveedor de servicios y
        a Pretal por cualquier lesión, daño o responsabilidad que surja de no
        buscar dicha atención, incluido el reembolso que de otro modo podría
        haber estado disponible bajo la Garantía de Pretal. Los Usuarios son
        responsables de los costos de dicho tratamiento médico para mascotas y,
        si usted es propietario de una mascota, autoriza al Prestador (ó en caso
        de emergencia a Pretal) a cargar dichos costos en su tarjeta de crédito
        u otro método de pago. En determinadas circunstancias, el propietario de
        una mascota puede ser elegible para recibir un reembolso en virtud de la
        Garantía Pretal. Pretal recomienda que todos los usuarios tengan un
        seguro de mascotas adecuado para cubrir los costos de atención
        veterinaria. Los Usuarios son responsables de los costos de dicho
        tratamiento médico para mascotas y, si usted es propietario de una
        mascota, autoriza al Prestador (ó en caso de emergencia a pretal) a
        cargar dichos costos en su tarjeta de crédito u otro método de pago. En
        determinadas circunstancias, el propietario de una mascota puede ser
        elegible para recibir un reembolso en virtud de la Garantía Pretal.
        Pretal recomienda que todos los usuarios tengan un seguro de mascotas
        adecuado para cubrir los costos de atención veterinaria. Los Usuarios
        son responsables de los costos de dicho tratamiento médico para mascotas
        y, si usted es propietario de una mascota, autoriza al Prestador (ó en
        caso de emergencia a pretal) a cargar dichos costos en su tarjeta de
        crédito u otro método de pago. Pretal recomienda que todos los usuarios
        tengan un seguro de mascotas adecuado para cubrir los costos de atención
        veterinaria. 6. Pago Usted entiende que el uso de los Servicios puede
        derivar en cargos que perciban los Prestadores (“Cargos”). Después de
        que haya recibido los Servicios, Pretal facilitará el pago de los Cargos
        que correspondan a los Prestadores, actuando exclusivamente como agente
        de cobro de éstos. El pago de los Cargos de dicha manera se considerará
        como efectuado directamente por usted al Prestador. Los Cargos incluirán
        los impuestos aplicables cuando se requiera por ley. Los Cargos pagados
        por usted a los Prestadores son definitivos y no reembolsables. Todos
        los Cargos son pagaderos inmediatamente luego de prestado el Servicio
        por parte del Prestador, y el pago de los mismos será procesado por
        Pretal actuando en el carácter arriba invocado como agente de cobro de
        los Prestadores, y actuando siempre por cuenta y orden del Prestador, y
        utilizando para ello los datos de la tarjeta de crédito que los Usuarios
        han provisto en la Aplicación y/o en el Sitio. Realizada dicha gestión
        de pago, Pretal enviará al Usuario por cuenta y orden de los
        Prestadores, una factura/recibo por correo electrónico a la dirección
        que los Usuarios hayan registrado en la Aplicación y/o en el Sitio. Si
        se determina que el método de pago de su Cuenta principal ha caducado,
        es inválido o de otro modo no sirve para cobrarle, usted acepta que
        Pretal, como agente de cobro limitado de los Prestadores, utilice un
        método de pago secundario en su Cuenta, si estuviera disponible. Usted
        podrá optar por cancelar su solicitud para los Servicios en cualquier
        momento antes de la provisión de los mismos, pero si dicha cancelación
        se produjese dentro de las dos horas previas al horario convenido con el
        Prestador para el inicio de la provisión de los mismos, el Usuario
        acepta que Pretal le cargue a su tarjeta de crédito el importe total del
        Servicio acordado con el Prestador, como si éste se hubiese prestado.
        Después de que haya recibido los Servicios por parte del Prestador, el
        Usuario tendrá la oportunidad de calificar su experiencia y dejar
        comentarios adicionales sobre el Prestador. 7. Renuncias; Limitación de
        responsabilidad; Indemnidad. a.- Renuncia. Los Servicios se proporcionan
        “tal cual” y como sean contratados entre el Usuario y el Prestador en
        cada caso. Pretal no hace declaración ni presta garantía alguna relativa
        a la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los
        Servicios de los Prestadores con los cuales el Usuario se vincule a
        través de la Aplicación y/o el Sitio, o que tales Servicios no serán
        interrumpidos, o estarán libres de errores por parte de los Prestadores.
        Al aceptar estas condiciones, el Usuario reconoce y acepta que todo
        posible riesgo derivado del uso de los Servicios será únicamente suyo
        y/o, en su caso, de cada Prestador, pero que (ninguno de tales
        potenciales riesgos es asumido por Pretal), en la máxima medida
        permitida por la ley aplicable. b.- Limitación de responsabilidad.
        Pretal no será responsable de daños indirectos, incidentales,
        especiales, ejemplares, punitivos o emergentes, incluidos el lucro
        cesante, la pérdida de datos, la lesión personal o el daño a la
        propiedad, ni de perjuicios relativos, o en relación con, o de otro modo
        derivados de cualquier uso de los Servicios, incluso aunque Pretal haya
        sido advertido de la posibilidad de dichos daños. Pretal no será
        responsable de cualquier daño, responsabilidad o pérdida que deriven de:
        (i) su uso o dependencia de los Servicios, o cualquier incapacidad de
        los Usuarios para acceder o utilizar los Servicios; o (ii) cualquier
        transacción o relación entre el Usuario y el Prestador, aunque Pretal
        hubiera sido advertido de la posibilidad de dichos daños. Pretal no será
        responsable del retraso o de la falta de ejecución de los Servicios por
        parte del Prestador. Indemnidad. Usted como Usuario acuerda mantener
        indemne y responder frente a Pretal y sus consejeros, socios,
        directores, empleados y agentes por cualquier reclamación, demanda,
        pérdida, responsabilidad y gasto (incluidos los honorarios de abogados)
        que deriven de: (i) el uso de los Servicios; (ii) el incumplimiento o
        violación de cualquiera de estas Condiciones; (iii) el uso por parte de
        Pretal de su Contenido de Usuario; o (iv) la alegada infracción de los
        derechos de cualquier tercero, incluidos los Prestadores. 8. Legislación
        aplicable; Jurisdicción Aplicable Salvo que aquí se especifique lo
        contrario, las presentes Condiciones se regirán e interpretarán
        exclusivamente en virtud de la legislación de la República Argentina.
        Cualquier disputa, conflicto, reclamación o controversia, del tipo que
        sea, que resulte de las presentes Condiciones o que se relacione con
        ellas o de cualquier modo con cualquier hipotético reclamo que el
        Usuario pudiera realizar a Pretal en base a o con relación a las mismas,
        incluyendo las relativas a su validez, interpretación y exigibilidad
        (cualquier “Disputa”), deberán someterse forzosamente a los Tribunales
        Nacionales Ordinarios en lo comercial con asiento en la Ciudad Autónoma
        de Buenos Aires, renunciando las partes a cualquier otro fuero o
        jurisdiccion que pudieren corresponderles. 9. Otras disposiciones Las
        reclamaciones por infracción de derechos de autor deberán enviarse al
        agente designado de Pretal (info@pretal.com.ar). Visite la página web de Pretal en
        https://www.pretal.com.ar para obtener las direcciones
        designadas e información adicional. 10. Notificaciones Al aceptar las
        presentes Condiciones usted acepta como Usuario, que Pretal podrá
        cursarle cualquier notificación relacionada a las presentes Condiciones,
        y/o a los Servicios, mediante un correo electrónico enviado a la
        dirección que usted constituya y denuncie en su Cuenta, o a elección de
        Pretal, por comunicación escrita enviada a su dirección postal que
        también usted haya constituido y designado al proveer los datos de
        apertura de su Cuenta (o con posterioridad, si hubiera registrado algún
        cambio), según lo dispuesto en su Cuenta. Usted podrá notificar a Pretal
        por comunicación escrita a info@pretal.com.ar. 11. Certificación de Cumplimiento de la Legislación Aplicable
        Al acceder y utilizar el Servicio La Aplicacion, usted certifica que: (1)
        tiene al menos 18 años de edad o la mayoría de edad en su jurisdicción,
        la que sea mayor, y (2) cumplirá con todas las leyes y reglamentaciones
        aplicables a su actividades realizadas a través de, o relacionadas con,
        el Servicio La Aplicacion. • Para los dueños de mascotas, esto significa,
        entre otras cosas, que se asegurará de que sus mascotas estén vacunadas,
        con licencia, etiqueta de identificación y/o microchip según lo exijan
        las leyes o regulaciones locales; que ha obtenido y mantendrá las
        pólizas de seguro obligatorias relativas a las mascotas cuyo cuidado
        confía a los Prestadores de servicios (y que dichas pólizas beneficiarán
        a terceros, incluidos los Prestadores de servicios, en la misma medida
        en que lo benefician a usted). • Para los Prestadores de servicios, esto
        significa, entre otras cosas, que usted certifica que es legalmente
        elegible para brindar Servicios de cuidado de mascotas en la
        jurisdicción donde brinda Servicios de cuidado de mascotas; que ha
        cumplido y cumplirá con todas las leyes y reglamentos que le son
        aplicables; que ha obtenido todas las licencias comerciales, registros
        de impuestos comerciales y permisos necesarios para proporcionar
        legalmente Servicios de cuidado de mascotas; y que, al proporcionar
        Servicios de cuidado de mascotas, cumplirá con las leyes aplicables
        sobre correas, eliminación de desechos de mascotas y leyes similares.
        Usted reconoce que Pretal tiene derecho a confiar en estas
        certificaciones de usted, no es responsable de garantizar que todos los
        usuarios hayan cumplido con las leyes y regulaciones aplicables, y no
        será responsable por el incumplimiento de un usuario. 12. Disposiciones
        generales Usted da su aprobación a Pretal para ceder o transferir estas
        Condiciones, en todo o en parte, incluido a: (i) una subsidiaria o un
        afiliado; (ii) un adquirente del capital, del negocio o de los activos
        de Pretal; o (iii) un sucesor por fusión. No existe entre usted, Pretal
        o cualquier Prestador una Aplicacion conjunta o relación de socios, empleo
        o agencia como resultado del contrato entre usted y Pretal o del uso de
        los Servicios. Si cualquier disposición de estas Condiciones se
        considerara ilegal, nula o inexigible, ya sea en su totalidad o en
        parte, de conformidad con cualquier legislación, dicha disposición o
        parte de ésta se considerará que no forma parte de estas Condiciones,
        aunque la legalidad, validez y exigibilidad del resto de las
        disposiciones de estas Condiciones no se verá afectada. En ese caso, las
        partes deberán reemplazar dicha disposición ilegal, nula o inexigible,
        en todo o en parte por una disposición legal, válida y exigible que
        tenga, en la medida de lo posible, un efecto similar al que tenía la
        disposición ilegal, nula o inexigible, dados los contenidos y el
        propósito de estas Condiciones. Estas Condiciones constituyen el íntegro
        entendimiento entre las partes en relación con el objeto y sustituye y
        reemplaza a todos los acuerdos anteriores o contemporáneos en relación
        con dicho objeto. En estas Condiciones, las palabras “incluido/a/os/as”
        e “incluye/n” significan “incluido, de forma meramente enunciativa”. 13
        Garantía Pretal Los propietarios de mascotas y los proveedores de
        servicios pueden beneficiarse de la Garantía Pretal , que está sujeto a
        estos términos y condiciones (los " Términos de la garantía "). Estos
        Términos de la garantía se aplican además de los términos de servicio de
        Pretal y otros términos y políticas establecidos en el servicio de
        Pretal. A menos que se defina lo contrario en estos términos de la
        garantía, todos los términos en mayúscula tendrán el significado
        establecido en los términos de servicio de Pretal. 13.1 - La garantía
        Pretal: Sujeto a todas las limitaciones, exclusiones y condiciones de
        estos términos de la garantía, Pretal acepta pagar (la " Garantía Pretal
        ") a una parte solicitante cuando una parte responsable no paga ciertos
        gastos veterinarios de la mascota, así como ciertos costos derivados del
        propietario de la mascota, daños a la propiedad o lesiones personales a
        un Tercero, todo como se establece específicamente a continuación (las "
        Pérdidas cubiertas "). Tal como se utiliza en el presente, " Parte
        Solicitante " significa y se limita a un Proveedor de Servicios
        aplicable o Dueño de una Mascota que es elegible para solicitar pagos
        por una Pérdida Cubierta bajo estos Términos de la garantía; “ Parte
        responsable ” significa, según corresponda, (a) un Proveedor de
        servicios cuyos actos u omisiones son la causa directa y principal de
        una Pérdida cubierta, o (b) un Dueño de mascota cuya mascota es la causa
        directa y principal de una Pérdida cubierta; y “ Tercero” se refiere a
        una persona física que no es un Proveedor de servicios, un Dueño de una
        mascota, el compañero de habitación, un miembro de la familia o una
        pareja de un Proveedor de servicios o un Dueño de una mascota, o
        cualquier otra persona que brinde atención a los animales en nombre del
        Proveedor de servicios. (Esto significa que la Garantía de Pretal no
        brinda cobertura por lesiones a los proveedores de servicios, dueños de
        mascotas o sus compañeros de habitación o familiares). El propósito de
        la Garantía Pretal es promover la confianza del usuario y aumentar el
        uso del Servicio Pretal. LA GARANTÍA PRETAL NO ES UN SEGURO. ESTOS
        TÉRMINOS DE GARANTÍA NO PRETENDEN SER UNA OFERTA PARA ASEGURAR NI UN
        CONTRATO DE SEGURO Y NO SUSTITUYEN NINGUNA COBERTURA DE SEGURO QUE USTED
        PUEDA TENER. EN LA MEDIDA EN QUE CUALQUIER USUARIO DEL PRETAL DESEE UNA
        PROTECCIÓN MÁS ALLÁ DEL ALCANCE DE LA GARANTÍA DE PRETAL, DEBERÁ COMPRAR
        UN SEGURO QUE PROPORCIONE UNA COBERTURA SUFICIENTE. NINGÚN PROPIETARIO
        DE MASCOTAS, PROVEEDOR DE SERVICIOS, TERCEROS U OTRA PERSONA, APARTE DEL
        SOLICITANTE QUE CUMPLE CON LAS CONDICIONES DE ESTOS TÉRMINOS DE GARANTÍA
        CON RESPECTO A LA CORRESPONDIENTE PÉRDIDA CUBIERTA, TIENE DERECHO BAJO
        ESTOS TÉRMINOS DE GARANTÍA O CUALQUIER OTRO MATERIAL O DECLARACIÓN CON
        RESPECTO A LA GARANTÍA PRETAL. Debe cumplir con todos los requisitos y
        condiciones aplicables para ser elegible para la Garantía Pretal. 13.2 -
        Condiciones Generales, Limitaciones y Exclusiones: Cuando presenta un
        reclamo de reembolso bajo la Garantía Pretal, acepta hacer todo lo
        posible para comunicarse de manera honesta y transparente con Pretal (o
        sus agentes), incluso proporcionando de inmediato la documentación o la
        información solicitada razonablemente por Pretal para ayudar con su
        proceso de revisión ( incluida la información sobre el seguro aplicable,
        como las pólizas de propietarios o inquilinos). Además, los siguientes
        términos y condiciones se aplican a todas las Pérdidas cubiertas: - (a)
        Los costos por los que solicita el reembolso deben surgir de lesiones o
        daños que ocurrieron durante las fechas de inicio y finalización
        programadas de una Reserva aceptada por el Proveedor de servicios y el
        Propietario de la mascota(y pagada) antes del día de la lesión o daño, y
        debe ser un resultado directo y próximo de los servicios reservados.
        (Esto significa que los costos que surjan de lesiones o daños sufridos
        durante un encuentro para conocer al Proveedor de servicios previo a la
        Reserva, o cualquier otro servicio no sujeto a una Reserva no son
        elegibles para reembolso). - (b) El pago de la Reserva durante la cual
        ocurrió la lesión o el daño debe superar los $1000 por fecha de servicio
        y debe completarse a través del Servicio Pretal. - (c) La lesión o daño
        por el cual solicita el reembolso debe haber sido causado directa e
        inmediatamente por los actos u omisiones de un Proveedor de servicios
        (o, en el caso de lesiones a la mascota de un Proveedor de servicios por
        parte de la mascota del Dueño de la mascota, los actos u omisiones del
        Titular). - (d) Debe notificar a Pretal de su reclamo a través del
        número de WhatsApp correspondiente o la dirección de correo electrónico
        (como se indica en la tabla al final de estos Términos de garantía)
        durante la Reserva (o, si es posterior, dentro de las 48 horas
        posteriores a la lesión o daño) . - (e) Antes de que se aplique la
        Garantía de Pretal, Pretal, a su discreción, primero intentará ayudar a
        las Partes Solicitantes a resolver los problemas con las Partes
        Responsables directamente o confirmar de otro modo de forma
        independiente que la Parte Responsable no quiere o no puede pagar. - (f)
        Si un Proveedor de servicios cede el cuidado, la custodia o el control
        de una mascota a un tercero durante el período de servicio de una
        Reserva, el reembolso no está disponible para ese Proveedor de servicios
        como Parte solicitante por lesiones a mascotas o daños que ocurran
        durante el período de servicio. período en que se cedió el cuidado, la
        custodia o el control. Si, durante el período de servicio de una
        Reserva, el Propietario de una mascota instruye, autoriza o hace que un
        Proveedor de servicios entregue la mascota del Propietario de la mascota
        a un tercero, el reembolso por lesiones o daños que ocurran durante esa
        Reserva tampoco estará disponible. - (g) La Garantía Pretal no se
        extiende a lo siguiente: - Daños, lesiones u otras pérdidas o reclamos
        que resulten del cuidado cualquier animal cuya propiedad o cuidado de
        terceros esté prohibido o sujeto a registro bajo , ley aplicable. -
        Daños, lesiones u otras pérdidas que surjan del cuidado de un proveedor
        de servicios de cualquier animal de un tercero junto con la mascota del
        propietario de una mascota durante una reserva. - Daños, lesiones u
        otras pérdidas o reclamos que surjan de alguna manera de la
        responsabilidad automotriz, incluidas las pérdidas que surjan de
        cualquier automóvil u otro accidente o incidente vehicular. - Daños,
        lesiones u otras pérdidas o reclamos que surjan de actos de la
        naturaleza, como terremotos, huracanes y tornados, o de exigencias
        causadas por otros factores fuera del control razonable de las partes,
        como (sin limitación) incendios forestales o falta o falla de servicios
        públicos o servicio de Internet debido a factores externos. - Pérdidas
        no causadas directamente por los actos u omisiones de una Parte
        Responsable, incluidos, entre otros, daños emergentes, daños especiales,
        interrupción del negocio, costos de oportunidad, pérdida de uso o
        pérdida asociada con el acceso no autorizado a los datos. - (h) La
        Garantía Pretal está sujeta a los Terminos de Servicio de Pretal.
        Pretal se reserva el derecho de rechazar una solicitud de reembolso en
        caso de que la pérdida, lesión o daño surja, en su totalidad o en parte,
        de la conducta de un reclamante que viole los Terminos de Servicio de
        Pretal. 13.3 - Pérdidas cubiertas por lesiones de mascotas: Sujeto a
        estos Términos de garantía (incluida, entre otras, la Sección 2
        anterior), Pretal reembolsará los gastos veterinarios verificables que
        surjan de lesiones a: (1) la mascota del propietario de una mascota
        mientras esté bajo el cuidado o control de un proveedor de servicios;
        (2) la propia mascota residente del Proveedor de servicios causada por
        la mascota del Dueño de la mascota bajo el cuidado del Proveedor de
        servicios; y (3) la mascota de un Tercero causada por la mascota del
        Dueño de la Mascota bajo el cuidado del Proveedor de Servicios. (a)
        Condiciones y limitaciones adicionales . Una reclamación de reembolso de
        gastos veterinarios de mascotas en virtud de la Garantía Pretal está
        sujeta a las siguientes condiciones y limitaciones adicionales: i -
        Dentro de los 14 días posteriores a la lesión, debe presentar
        documentación escrita de un veterinario en ejercicio certificado por la
        junta que (a) especifique los costos incurridos por los cuales se
        solicita el reembolso, (b) establezca el diagnóstico y confirme que la
        lesión no fue el resultado de una enfermedad , condición específica de
        la raza o condición preexistente, (c) confirma que la lesión ocurrió
        durante el período de tiempo de la Reserva, y (d) proporciona cualquier
        otra información que razonablemente solicitemos. ii - Solo los costos
        incurridos por el tratamiento brindado dentro de los 30 días posteriores
        a la fecha de la lesión son elegibles para reembolso. iii - El reembolso
        está limitado a $100.000 por ocurrencia/incidente. iv - Se requiere una
        contribución mínima de $10.000 por incidente relacionado con la
        solicitud, pagadero por el propietario de la mascota por lesiones a la
        mascota del propietario de la mascota y por el proveedor de servicios
        por lesiones a la mascota del proveedor de servicios. Esto significa que
        las solicitudes de reembolso que no superen los $10.000 no generarán
        ningún reembolso. (b) Exclusiones específicas . Los siguientes no son
        elegibles para reembolso bajo esta Sección 3: i - Costos incurridos más
        de 30 días después de la fecha de la lesión, incluidos, entre otros, los
        costos de atención a largo plazo que resulten de la lesión. ii - Costos
        o responsabilidades que surjan de la negativa del propietario de la
        mascota, del proveedor de servicios o de un tercero a autorizar la
        atención médica para su propia mascota. iii - Lesiones resultantes de la
        contracción de pulgas, garrapatas, gusanos u otros parásitos de una
        mascota durante una Reserva (o la recurrencia de un problema de
        parásitos que se contrajo por primera vez antes de la Reserva). iv -
        Lesiones resultantes de (o de la recurrencia de) una Condición
        preexistente, Condición específica de la raza, Condición crónica,
        Condición ortopédica, Enfermedad de causa indeterminada, Enfermedad
        prevenible o Proptosis (según se definen dichos términos en la
        Subsección (c) a continuación). v - Costos o gastos resultantes del
        embarazo, enfermedad o recurrencia de la enfermedad de una mascota, o
        para atención preventiva. vi - Gastos no médicos de ningún tipo
        (incluyendo formación profesional, aseo y alimentación de mascotas). vii
        - Cualquier costo o gasto que no sean daños directos que resulten de una
        lesión a la mascota del Propietario de la mascota, del Proveedor de
        servicios o de un Tercero aplicable, incluidos, entre otros, aquellos
        que resulten de (a) lesiones personales al Proveedor de servicios, al
        Propietario de la mascota o a cualquier tercero, ( b) daños a la
        propiedad o responsabilidad, (c) lesiones a cualquier otra mascota, (d)
        angustia emocional o "dolor y sufrimiento", o (e) pérdida de salarios,
        planes de viaje cancelados o cualquier otro gasto incidental. (c)
        Definiciones clave . Los términos en mayúscula utilizados anteriormente
        en esta Sección 3 tienen los siguientes significados: i - “ Condición
        preexistente ” hace referencia a cualquier lesión, enfermedad o
        condición, ya sea que haya sido diagnosticada por un veterinario o no,
        que afectó a una mascota antes de una Reserva, incluidas, entre otras,
        infecciones respiratorias, infecciones del tracto urinario/vejiga,
        trastornos sanguíneos, enfermedad periodontal/dental y vómitos, diarrea
        y otros trastornos gastrointestinales. ii - Condición específica de la
        raza” se refiere a cualquier condición que ocurre con frecuencia en una
        raza de perro en particular, incluido, entre otros, el síndrome
        braquicefálico (común en pequinés, bulldog, bulldog francés, carlino,
        chow chow, boston terrier y otros), displasia de cadera y/o codo (común
        en perros afganos). sabuesos, beagles, border collies, chow chows,
        golden retrievers, pastores alemanes, gran danés, labrador retrievers y
        otros), hipotiroidismo (común en bulldogs, border collies, boston
        terriers, caniches, gran danés, setters ingleses y schnauzers estándar y
        otros, especialmente las razas grandes), enfermedad del disco
        intervertebral (IVDD) (común en Beagles, Cocker Spaniels, Setters
        ingleses, Bulldogs franceses, Pequinés, Pugs y otros), otros problemas
        de disco (común en Basset Hounds, Bulldogs, Corgis, Dachshunds,
        Pomerania , Pugs y otros), luxación patelar (común en Boston
        Terriers,Cavalier King Charles, chihuahuas, pomeranians, poodles,
        yorkshire terriers y otros, especialmente razas pequeñas), y dilatación
        gástrica y vólvulo, también conocido como hinchazón (común en gran
        danés, pastor alemán, san bernardo, labrador retriever, perro lobo
        irlandés, gran Pyrenees, Boxer, Weimaraner, Old English Sheepdog, Irish
        Setter, Collie, Bloodhound, Standard Poodle y otros). iii - “ Condición
        crónica ” significa una enfermedad o condición que es persistente o que
        tiene efectos duraderos y que generalmente no se puede prevenir con
        vacunas ni curar con medicamentos, incluidas, entre otras, alergias,
        puntos calientes, infecciones de oído, cáncer, diabetes, EII
        (inflamatoria). enfermedad intestinal), enfermedad de Addison,
        enfermedad de Cushing, ojo seco (QCS), epilepsia, glaucoma,
        hipotiroidismo o hipertiroidismo, lipomas y masas en la piel, y
        cristales u obstrucción urinaria o vesical. iv - “ Condición ortopédica
        ” significa un trastorno del sistema musculoesquelético y cualquier
        músculo, articulación y ligamento asociado. Esto incluye, entre otros,
        artritis, IVDD y desgarros del ligamento cruzado, tenosinovitis
        biceptal, displasia de codo, displasia de cadera, proceso coronoides
        medial fragmentado del cúbito (FMCP), enfermedad o lesión ortopédica en
        el lado opuesto de una lesión anterior, osteocondrosis ( TOC),
        osteosarcoma y otros cánceres ortopédicos, luxación patelar y proceso
        ancóneo no unido (UAP). v - “ Enfermedad de causa indeterminada ”
        significa cualquier condición para la cual la causa es indeterminada o
        cualquier condición que equivalga a un “diagnóstico de exclusión”,
        incluidas, entre otras, condiciones autoinmunes, gastroenteritis
        hemorrágica, pancreatitis, colitis, síntomas relacionados con el estrés,
        coronavirus entérico, enfermedad cardíaca, Bordetelosis ("tos de las
        perreras"), infección por parvovirus canino ("parvo"), moquillo,
        influenza canina, enfermedad gastrointestinal, cualquier forma de cáncer
        y anemia hemolítica inmunomediada. vi - “ Enfermedad prevenible ” se
        refiere a cualquier enfermedad o afección que podría haberse prevenido
        mediante vacunación o atención veterinaria normal, incluidas, entre
        otras, la rabia, la infección por parvovirus canino ("parvo"), la
        leptospirosis, el moquillo, el adenovirus2, la parainfluenza, el
        coronavirus entérico, la influenza canina, la enfermedad de Lyme
        enfermedad, bordetelosis ("tos de las perreras"), enfermedad del gusano
        del corazón, gusanos intestinales, sarna y estafilococos. vii - “
        Proptosis ” significa el desplazamiento de un ojo fuera de la cuenca del
        ojo, que generalmente ocurre después de un traumatismo en la cabeza.
        13.4 - Pérdidas cubiertas por daños a la propiedad del dueño de la
        mascota Sujeto a estos Términos de garantía (incluida, entre otros, la
        Sección 2 anterior), Pretal reembolsará al Dueño de la mascota como
        Parte solicitante los gastos que el Proveedor de servicios esté
        legalmente obligado (pero no) a pagar por daños a la propiedad personal
        del Dueño de la mascota (que no sean lesiones o daños a su mascota,
        cubiertos anteriormente en la Sección 3). (a) Condiciones y limitaciones
        adicionales . Una reclamación de reembolso de los gastos derivados de
        daños a la propiedad del propietario de la mascota está sujeta a las
        siguientes condiciones y limitaciones adicionales: i - Dentro de los 14
        días posteriores al incidente que causó el daño a la propiedad, debe
        presentar documentación escrita del mismo, incluidas fotografías claras
        del daño reclamado, prueba de propiedad y recibos u otra evidencia
        confiable del valor justo de mercado y/o costo de reparación, y
        proporcionar cualquier otra información que solicitemos razonablemente.
        ii - Solo los gastos que surjan de los daños incurridos durante la
        Reserva y pagados por la reparación que ocurran dentro de los 30 días
        posteriores a la Reserva son elegibles para reembolso. iii - El
        reembolso está limitado a $100.000 por ocurrencia/incidente, pero en
        todos los casos no excederá el costo de reemplazo o el costo de
        reparación, lo que sea menor, a discreción exclusiva de Pretal. iv - Se
        requiere una contribución mínima de $10.000 por incidente relacionado
        con la solicitud, a cargo del propietario de la mascota, por daños a la
        propiedad del propietario de la mascota. Esto significa que las
        solicitudes de reembolso que no superen los $10.000 no generarán ningún
        reembolso. (b) Exclusiones específicas . Los siguientes no son elegibles
        para reembolso: i - Daños a la propiedad personal del dueño de la
        mascota causados ​​por su mascota. ii - Daños a la propiedad del
        Proveedor de servicios (o cualquier persona relacionada, que viva con o
        brinde atención en nombre del Proveedor de servicios). iii - Desgaste
        ordinario, es decir, el deterioro que se produce en condiciones y uso
        normales. iv - Pérdida debido a actos intencionales o delictivos,
        incluido el robo. v - Cualquier forma de vehículo automotor, incluidos,
        entre otros, automóviles, aeronaves o embarcaciones. vi - Divisas,
        dinero, metales preciosos, valores o instrumentos negociables. vii -
        Cultivos, madera u otras características naturales o cultivadas de la
        tierra. viii - Alimentos, consumibles y otros perecederos. ix - Bellas
        artes o reliquias familiares, es decir, pinturas, grabados, fotografías
        impresas, cuadros, tapices, vidrios raros o artísticos, ventanas de
        vidrio artístico, valiosas alfombras, estatuas, esculturas, muebles
        antiguos, joyas antiguas, baratijas, porcelanas, monedas, sellos , otros
        artículos de colección, colecciones, pieles, joyas, piedras preciosas,
        películas analógicas o grabaciones de audio, negativos, registros,
        manuscritos, dibujos y otros bienes similares de rareza o valor
        histórico, de archivo o sentimental. x - Datos electrónicos, lo que
        significa grabaciones de audio o video, fotografías, dibujos, registros,
        manuscritos, datos, información, archivos, hechos, programas u otros
        materiales almacenados en formato digital o electrónico, ya sea en
        software de computadora, software de aplicación de sistemas, disco,
        CD-ROM , cintas, unidades, celdas, dispositivos de procesamiento de
        datos, almacenamiento en la nube o cualquier otro medio. 13.5 - Pérdidas
        cubiertas por lesiones de terceros. Sujeto a estos Términos de garantía
        (incluida, entre otras, la Sección 2 anterior), Pretal reembolsará a un
        Tercero, en nombre de una Parte solicitante, los costos que surjan
        directamente de lesiones físicas y personales a un Tercero como
        resultado de la interacción con la mascota del Dueño de una mascota.
        mientras esté bajo el cuidado de dicho Proveedor de servicios, cuando
        Pretal acepte (actuando razonablemente) que dicha lesión daría lugar a
        un reclamo legal válido por parte del Tercero. (a) Condiciones y
        limitaciones adicionales . Una reclamación de reembolso de los gastos
        que surjan de la lesión de un Tercero está sujeta a las siguientes
        condiciones y limitaciones adicionales: i- Dentro de los 14 días
        posteriores al incidente que causó la lesión personal, debe presentar
        documentación escrita de la misma, incluidas fotografías claras de la
        lesión, recibos verificables del tratamiento médico necesario y
        cualquier explicación de los beneficios, y (z) proporcionar cualquier
        otra información que solicitamos razonablemente. ii- Solo las lesiones
        sufridas durante una Reserva son elegibles para reembolso. iii- El
        reembolso está limitado a $200.000 por ocurrencia/incidente (el
        "Límite") (b) Exclusiones específicas . Los siguientes no son elegibles
        para reembolso: i - Costos, gastos o daños basados ​​en la asunción de
        responsabilidad de un Proveedor de Servicios en un contrato o acuerdo
        escrito. ii - Costos, gastos o daños basados ​​en reclamos por
        difamación, infracción de propiedad intelectual u otros daños personales
        y publicitarios. iii - Pérdidas debidas a actos intencionales o
        delictivos, incluido el asalto. iv - Lesiones o daños físicos o
        personales a los proveedores de servicios, dueños de mascotas o sus
        respectivos compañeros de habitación o familias. 13.6 - Acuerdos de
        Prestadores de Servicios y Dueños de Mascotas. (a) Inspección de Pérdida
        . Cada Parte Solicitante acuerda presentar o poner a disposición para su
        inspección, en los lugares y momentos razonables que Pretal o cualquier
        administrador o agente de reclamos pueda designar, todas las pruebas de
        pérdida, daño, costo, propiedad u otra información relacionada con el
        reclamo. Sin perjuicio de lo anterior, Pretal no tendrá ninguna
        responsabilidad ante los propietarios de mascotas, los proveedores de
        servicios o cualquier otra persona debido a cualquier inspección o falta
        de inspección. (b) Cumplimiento legal . Los Proveedores de servicios son
        los únicos responsables de tener un seguro suficiente para cumplir con
        los requisitos legales en las jurisdicciones donde los Proveedores de
        servicios brindan servicios. La Garantía Pretal no es suficiente para
        ese propósito. Pretal no verifica si los proveedores de servicios han
        obtenido un seguro, y se recomienda a los propietarios de mascotas que
        consulten directamente con los proveedores de servicios sobre este tema.
        (c) Liquidación de Reclamaciones . Pretal puede condicionar el pago
        final de los costos bajo la Garantía de Pretal a la ejecución de un
        Acuerdo de liquidación de garantía, incluida la liberación de reclamos
        contra Pretal o cualquier otra parte aplicable y la obligación de
        mantener confidencial el monto del reembolso y las circunstancias.
        Cuando un reclamo aprobado bajo la Garantía Pretal involucre la Pérdida
        cubierta de una parte que no sea la Parte solicitante, Pretal se reserva
        el derecho (pero no tiene la obligación) de pagar la totalidad o una
        parte del monto aprobado a dicho tercero. (d) Compensación; subrogación
        _ Los montos pagaderos en virtud de la Garantía Pretal superan la
        cobertura de seguro aplicable. Pretal se reserva el derecho de compensar
        o deducir de los montos pagaderos a usted cualquier monto que pueda
        tener u obtener de cualquier otra persona o entidad obligada a compensar
        las Pérdidas cubiertas. Pretal y/o las aseguradoras de Pretal tienen
        derecho a subrogarse en cualquier persona o entidad presuntamente
        responsable de causar los siniestros en cuestión, incluso si esa persona
        o entidad es usted. Por la presente acepta que, en relación con
        cualquier pago realizado bajo la Garantía Pretal, cooperará plenamente
        con Pretal en sus esfuerzos de subrogación. (e) Enmienda; Terminación.
        Salvo que lo prohíba la ley, Pretal puede modificar o rescindir la
        Garantía Pretal en cualquier momento, y dicha modificación o rescisión
        se aplicará a los incidentes que ocurran después de la fecha de vigencia
        de la modificación o rescisión. Pretal también se reserva el derecho de
        modificar la Garantía Pretal en la medida necesaria para cumplir con la
        ley aplicable, incluso en el caso de que una autoridad reguladora
        determine que Pretal debe obtener una licencia u otro permiso
        gubernamental para proporcionar la Garantía Pretal. Además, excepto en
        el caso de una demanda presentada para hacer cumplir estos Términos de
        la Garantía, usted reconoce y acepta que todos los beneficios
        disponibles para usted bajo la Garantía Pretal terminarán inmediatamente
        en caso de que inicie cualquier acción, demanda o reclamo contra Pretal,
        o sus funcionarios, directores. (f) Sin renuncia . El hecho de que
        Pretal no haga cumplir cualquier derecho o disposición de estos Términos
        de garantía no constituirá una renuncia a la aplicación futura de ese
        derecho o disposición. La renuncia a cualquier derecho o disposición
        será efectiva solo si se hace por escrito y está firmada por un
        representante debidamente autorizado de Pretal. (g) Exenciones de
        responsabilidad, Limitación de responsabilidad, Arbitraje . Usted
        reconoce y acepta que cualquier reclamo que surja de o esté relacionado
        con la Garantía Pretal o estos Términos de garantía están sujetos a los
        Terminos de Servicio de Pretal, incluida la Exención de responsabilidad
        de la garantía y (excepto por la obligación de Pretal de pagar montos de
        conformidad con una solicitud de reembolso aprobada realizada bajo el
        cuidador). Garantía las limitaciones de responsabilidad establecidas en
        los Terminos de Servicio de Pretal. Además, reconoce y acepta que
        cualquier disputa o reclamo relacionado de alguna manera con la Garantía
        de Pretal se adjudicará de acuerdo con la forma aplicable establecida en
        los Terminos de Servicio de Pretal.
      </div>
    </div>
  );
};
