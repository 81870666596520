import { FunctionComponent } from "react";

interface Props {
  data: any;
}

export const Header: FunctionComponent<Props> = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="header-custom-container">
            <div style={{ flex: 1, justifyContent: "space-evenly" }}>
              <div className="intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p
                  style={{
                    fontSize: "24px",
                    color: "rgb(92 122 195)",
                    fontWeight: 600,
                  }}
                >
                  <div>
                    <span style={{ color: "#E7A25E" }}>Encontrá</span> personas
                    en Argentina que{" "}
                    <span style={{ color: "#E7A25E" }}>
                      cuiden a tu mascota
                    </span>{" "}
                    como si fueran de su propia familia.
                  </div>
                </p>
                <a href="https://play.google.com/store/apps/details?id=com.dbusc0793.petralfrontapp">
                  <img
                    src="https://pretaldev-storage-65bc4cdb185512-dev.s3.us-east-2.amazonaws.com/public/google-play.png"
                    alt="Descargar Android"
                    style={{ width: "150px" }}
                  ></img>
                </a>
                <a href="https://apps.apple.com/ar/app/pretal/id6584529938">
                <img
                  src="https://pretaldev-storage-65bc4cdb185512-dev.s3.us-east-2.amazonaws.com/public/app-store.png"
                  alt="Descargar IOS"
                  style={{ marginLeft: 20, width: "150px", height: "45px" }}
                ></img>
                </a>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="https://pretaldev-storage-65bc4cdb185512-dev.s3.us-east-2.amazonaws.com/public/main-landing.png"
                alt="Main Landing"
                style={{ width: "100%", height: "auto" }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
